import React, { useEffect, useState } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import "../post-arrival-form/postarrival.css";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import Select from "react-select";
import { useMedflickApi } from "../../context/medflickContext";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const PatientAdmisiion = () => {
  const { pid, uid } = useParams();
  const { getAllHospitals, hospitals, doctors } = useMedflickApi();
  const [loading, setLoading] = useState(false);
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const [selectedHospitals, setSelectedHospitals] = useState("");
  const [selectedDoctors, setSelectedDoctors] = useState("");
  const [patientPrescription, setPatientPrescription] = useState(null);
  const [formData, setFormData] = useState({
    treatment_plan: "",
    surgery_name: "",
    cost: "",
    stay: "",
    discharge_date: "",
    description: "",
  });

  useEffect(() => {
    getAllHospitals();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlehospitalChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };

  const handleDoctorChange = (selectedOptions) => {
    setSelectedDoctors(selectedOptions);
  };

  const hospitalOptions = hospitals.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));
  const doctorOptions = doctors?.map((hospital) => ({
    value: hospital.id,
    label: "Dr. " + hospital.first_name + " " + hospital.last_name,
  }));

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPatientPrescription(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const payload = new FormData();
      payload.append("patient_id", patinetId); // Ensure `patinetId` is defined
      payload.append("user_id", userId); // Ensure `userId` is defined
      payload.append("doctor_id", selectedDoctors?.value);
      payload.append("surgery_name", formData.surgery_name);
      payload.append("treatment_plan", formData.treatment_plan);
      payload.append("discharge_date", formData.discharge_date);
      payload.append("cost", formData.cost);
      payload.append("stay", formData.stay);
      payload.append("description", formData.description);
      payload.append("hospital_id", selectedHospitals.value); // Ensure `selectedHospitals.value` is valid
      if (patientPrescription) {
        payload.append("discharge_paper_upload", patientPrescription);
      }

      const response = await axiosInstance.post("/patient_admission", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        alert("Form submitted successfully!");
        setFormData({
          treatment_plan: "",
          surgery_name: "",
          cost: "",
          stay: "",
          discharge_date: "",
          description: "",
        });
        setPatientPrescription(null);
        setSelectedHospitals(null);
        setSelectedDoctors("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <section id="medflick-mod">
        <div className="medflick-mod">
          <LeftSlider />
          <div className="medflick-mod-right">
            <div className="scrollbar-1">
              <div className="post-arrival-form">
                <h2>Patient Admission Form</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="pickup">Select Hospital</label>
                    <Select
                      name="hospitals"
                      options={hospitalOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedHospitals}
                      onChange={handlehospitalChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="doctor">Select Doctor</label>
                    <Select
                      name="hospitals"
                      options={doctorOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedDoctors}
                      onChange={handleDoctorChange}
                    />
                  </div>

                  {/* <div className="form-group">
                    <label htmlFor="treatment_plan">Treatment </label>
                    <input
                      type="text"
                      id="treatment_plan"
                      name="treatment_plan"
                      placeholder="Enter treatment_plan..."
                      value={formData.treatment_plan}
                      onChange={handleChange}
                    />
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="surgery_name">Surgery Name</label>
                    <input
                      type="text"
                      id="surgery_name"
                      name="surgery_name"
                      placeholder="Enter city"
                      value={formData.surgery_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="cost">Treatment Cost</label>
                    <input
                      type="text"
                      id="cost"
                      name="cost"
                      placeholder="Enter hotel name"
                      value={formData.cost}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="stay">Stay</label>
                    <input
                      type="text"
                      id="stay"
                      name="stay"
                      placeholder="Write here...."
                      value={formData.stay}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="discharge_date">Discharge Date</label>
                    <input
                      type="date"
                      id="discharge_date"
                      name="discharge_date"
                      value={formData.discharge_date}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      name="description"
                      placeholder="Enter description"
                      value={formData.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label htmlFor="discharge_paper_upload">
                      Discharge Paper Upload
                    </label>
                    <input
                      type="file"
                      id="discharge_paper_upload"
                      name="discharge_paper_upload"
                      onChange={handleFileChange}
                    />
                  </div>

                  {/* <div className="form-group">


                    <label htmlFor="status">Status</label>
                    <select id="status" name="status">
                      <option value="">Select status</option>
                      <option value="pending">Pending</option>
                      <option value="completed">Completed</option>
                      <option value="canceled">Canceled</option>
                    </select>
                  </div> */}

                  <button
                    type="submit"
                    className="submit-button"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PatientAdmisiion;
