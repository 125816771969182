import React, { useEffect, useState } from "react";
import { useMedflickApi } from "../../context/medflickContext";
import Select from "react-select";
const TreatmentList = ({ setSelectedTreatment, selectedTreatment }) => {
  const [tvalue, setTvalue] = useState("");
  const {
    getAllTreatment,
    treatmentsList,
    getAllUploadedTreatment,
    treatmentUploadList,
  } = useMedflickApi();

  useEffect(() => {
    getAllTreatment();
    getAllUploadedTreatment();
  }, []);

  const mergeAndFilterLists = (list1, list2) => {
    const combinedList = [...list1, ...list2];
    const uniqueList = [];

    const ids = new Set();

    combinedList.forEach((item) => {
      if (!ids.has(item.id)) {
        ids.add(item.id);
        uniqueList.push(item);
      }
    });

    return uniqueList;
  };

  const combinedTreatmentList = mergeAndFilterLists(
    treatmentsList,
    treatmentUploadList
  );

  const options = combinedTreatmentList.map((treatment) => ({
    value: treatment.id,
    label: treatment.name,
  }));

  const handleSelectChange = (selectedOption) => {
    setTvalue(selectedOption);
    setSelectedTreatment(selectedOption?.value);
    console.log("Selected treatment:", selectedOption?.value);
  };

  return (
    <>
      <div className="ding">
        <Select
          options={options}
          placeholder="Select Department"
          onChange={handleSelectChange}
          value={tvalue}
          isClearable
        />
      </div>
    </>
  );
};

export default TreatmentList;
