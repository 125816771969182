import React, { useState } from "react";
import LeadList from "./LeadList";
import Graph from "./Graph";
import Filter from "./Filter";
import LeftSlider from "../../Inc/LeftSlider";
import EditPatient from "./EditPatient";
import LeadSearch from "../../common/LeadSearch";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Home = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const { displayData, setPlatformSelect, platformSelect, setLeadStatus, leadStatus } = useGetLeadApi();
  const [selectedTreatment, setSelectedTreatment] = useState(null);

  const toggleEdit = (id) => {
    setShowEdit(!showEdit);
    setSelectedPatientId(id);
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
    } else if (name === "endDate") {
      setEndDate(value);
    }
    setPlatformSelect("");
  };

  const handleFilter = () => {
    setStartDate("");
    setEndDate("");
  };


  const handleDownload = () => {
    const filteredData = displayData.map((lead) => {
      let patientName = lead?.patient_name;
      let patientPhone = lead?.patient_phone
      let patientEmail = lead?.patient_email

      // Implement the condition for patient name
      if (
        lead?.platform === "Website Query" ||
        lead?.platform === "Landing Page"
      ) {
        if (!patientName || patientName.trim() === "") {
          return null; // Skip this entry
        }
      }

      if (lead?.platform === "Tawk_TO") {
        if (!patientName || patientName.trim() === "") {
          return null; // Skip this entry
        }
        const nameOnly = patientName.split("\r\n")[0];
        patientName = nameOnly;
      } else {
        const parsedFormData = JSON.parse(lead?.form_data);
        const fullNameData = parsedFormData?.find(
          (field) => field.name === "full_name"
        );
        const phoneDat = parsedFormData?.find(
          (field) => field.name === "phone_number"
        );
        const emailDat = parsedFormData?.find(
          (field) => field.name === "email"
        );
        patientName = fullNameData?.values?.[0] || lead?.patient_name;
        patientPhone = phoneDat?.values?.[0] || lead?.patient_phone;
        patientEmail = emailDat?.values?.[0] || lead?.patient_email;
      }

      // Construct the object for this lead
      return {
        patient_name: patientName,
        patient_email: patientEmail,
        patient_phone: patientPhone,
        platform: lead.platform,
      };
    });

    // Filter out null entries (skipped leads)
    const validData = filteredData.filter((data) => data !== null);

    // Create and download the Excel file
    const ws = XLSX.utils.json_to_sheet(validData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leads");
    XLSX.writeFile(wb, "Leads_Data.xlsx");
  };

  const handlePdfDownload = () => {
    const filteredData = displayData.map((lead) => {
      let patientName = lead?.patient_name;
      let patientPhone = lead?.patient_phone;
      let patientEmail = lead?.patient_email;


      if (
        lead?.platform === "Website Query" ||
        lead?.platform === "Landing Page"
      ) {
        if (!patientName || patientName.trim() === "") {
          return null; // Skip this entry
        }
      }

      if (lead?.platform === "Tawk_TO") {
        if (!patientName || patientName.trim() === "") {
          return null;
        }
        const nameOnly = patientName.split("\r\n")[0];
        patientName = nameOnly;
      } else {
        const parsedFormData = JSON.parse(lead?.form_data);
        const fullNameData = parsedFormData?.find(
          (field) => field.name === "full_name"
        );
        const phoneDat = parsedFormData?.find(
          (field) => field.name === "phone_number"
        );
        const emailDat = parsedFormData?.find(
          (field) => field.name === "email"
        );
        patientName = fullNameData?.values?.[0] || lead?.patient_name;
        patientPhone = phoneDat?.values?.[0] || lead?.patient_phone;
        patientEmail = emailDat?.values?.[0] || lead?.patient_email;
      }

      return {
        patient_name: patientName,
        patient_email: patientEmail,
        patient_phone: patientPhone,
        platform: lead.platform,
      };
    });

    // Filter out null entries (skipped leads)
    const validData = filteredData.filter((data) => data !== null);

    // Generate PDF
    const doc = new jsPDF();
    const tableData = validData.map((item) => [
      item.patient_name,
      item.patient_email,
      item.patient_phone,
      item.platform,
    ]);

    doc.text("Leads Data", 14, 10);
    doc.autoTable({
      head: [["Patient Name", "Email", "Phone", "Platform"]],
      body: tableData,
    });

    doc.save("Leads_Data.pdf");
  };

  return (
    <>
      <div className="medflick-mod">
        <LeftSlider path="home" />
        <div className="medflick-mod-right">
          <div className="scrollbar-1">
            <Graph />

            <div className="leads-search-doc">
              <h2>Leads</h2>
              <div className="leads-crm-search">
                <LeadSearch />
                <Filter
                  setPlatformSelect={setPlatformSelect}
                  platformSelect={platformSelect}
                  setSelectedTreatment={setSelectedTreatment}
                  selectedTreatment={selectedTreatment}
                  setLeadStatus={setLeadStatus}
                  leadStatus={leadStatus}
                />
              </div>
            </div>
            <div className="lead-search">
              <input
                type="date"
                name="startDate"
                className="lead-search-input"
                value={startDate}
                onChange={handleDateChange}
                placeholder="Start Date"
              />
              <input
                type="date"
                name="endDate"
                className="lead-search-input"
                value={endDate}
                onChange={handleDateChange}
                placeholder="End Date"
              />
              <button className="lead-search-button" onClick={handleFilter}>
                Clear
              </button>
            </div>
            {startDate && endDate && (
              <>
                <div className="leads-search-doc" style={{ marginTop: "10px" }}>
                  <h2>
                    Total Number of Leads{" "}
                    <span style={{ fontWeight: "bold" }}>{startDate}</span> to{" "}
                    <span style={{ fontWeight: "bold" }}>{endDate}</span> ={" "}
                    <span style={{ color: "#ff6800", fontWeight: "bold" }}>
                      {displayData?.length} Leads
                    </span>
                  </h2>
                </div>
                <button className="lead-search-button" onClick={handleDownload}>
                  Download Excel
                </button>
                <button className="lead-download-button" onClick={handlePdfDownload} style={{ marginLeft: "15px" }}>
                  Download PDF
                </button>
              </>
            )}

            <div className="leads-patient-box">
              <div className="leads-patient-head">
                <div className="leads-patient-head-box1">Patient Name</div>
                {/* <div className="leads-patient-head-box7">Created At</div> */}
                <div className="leads-patient-head-box2">Country</div>
                <div className="leads-patient-head-box3">Source</div>
                <div className="leads-patient-head-box4">Lead Type</div>
                <div className="leads-patient-head-box5">Edit</div>
                <div className="leads-patient-head-box6">Assign To</div>
                <div className="leads-patient-head-box7">Status</div>
                <div className="leads-patient-head-box8">Action</div>
              </div>

              <LeadList
                toggleEdit={toggleEdit}
                showEdit={showEdit}
                startDate={startDate}
                endDate={endDate}
                platformSelect={platformSelect}
                selectedTreatment={selectedTreatment}
                leadStatus={leadStatus}
              />
            </div>
          </div>
        </div>
      </div>
      <EditPatient
        toggleMessage={toggleEdit}
        showMessage={showEdit}
        selectedPatientId={selectedPatientId}
      />
    </>
  );
};

export default Home;
