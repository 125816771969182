import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const VisaInvitationEmailList = ({
  visaInvitationEmail,
  getBackgroundColor,
  leadDetail,
  hospitals,
  getHospitalNameById,
  encryptId,
  getHospitalImageById,
}) => {
  return (
    <>
      {visaInvitationEmail?.length > 0 ? (
        <div className="opinion-grid">
          {visaInvitationEmail.map((opinion) => (
            <div key={opinion?.id} className="opinion-item">
              <Link
                to={`/hospital-visa-letter/${encryptId(
                  opinion?.patient_id
                )}/${encryptId(opinion?.user_id)}/${encryptId(opinion?.id)}`}
              >
                <img
                  src={`https://images.medflick.com/hospital/${getHospitalImageById(
                    opinion?.hospital_id,
                    hospitals
                  )}`}
                  alt="Hospital"
                  className="hospital-image"
                />
                <span className="hospital-name">
                  {getHospitalNameById(opinion?.hospital_id, hospitals)}
                </span>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No Hospital Visa available.</p>
      )}
      {/* {visaInvitationEmail?.length > 0 ? (
        <div className="table-container">
          <table className="visa-invitation-table">
            <thead>
              <tr>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Patient Name
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Patient Passport
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Name 1
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Passport 1
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Name 2
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Passport 2
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Name 3
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Passport 3
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Name 4
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Passport 4
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Embassy
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Hospital
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Appointment Date
                </th>
              </tr>
            </thead>
            <tbody>
              {visaInvitationEmail?.map((data) => (
                <tr key={data.id}>
                  <td>{leadDetail?.patient_name}</td>
                  <td>
                    <a
                      href={data.patient_passport}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.patient_passport !== "null"
                        ? "View Passport"
                        : "No Passport"}
                    </a>
                  </td>
                  <td>{data.attendant_name1}</td>
                  <td>
                    <a
                      href={data.attendant_passport1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.attendant_passport1 !== "null"
                        ? "View Passport"
                        : "No Passport"}
                    </a>
                  </td>
                  <td>
                    {data.attendant_name2 !== "null"
                      ? data.attendant_name2
                      : "No Attendant"}
                  </td>
                  <td>
                    <a
                      href={data.attendant_passport2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.attendant_passport2 !== "null"
                        ? "View Passport"
                        : "No Passport"}
                    </a>
                  </td>
                  <td>
                    {data.attendant_name3 !== "null"
                      ? data.attendant_name3
                      : "No Attendant"}
                  </td>
                  <td>
                    <a
                      href={data.attendant_passport3}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.attendant_passport3 !== "null"
                        ? "View Passport"
                        : "No Passport"}
                    </a>
                  </td>
                  <td>
                    {data.attendant_name4 !== null
                      ? data.attendant_name4
                      : "No Attendant"}
                  </td>
                  <td>
                    <a
                      href={
                        data.attendant_passport4 !== "null"
                          ? data.attendant_passport4
                          : null
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.attendant_passport4 !== "null"
                        ? "View Passport"
                        : "No Passport"}
                    </a>
                  </td>
                  <td>{data.embassy}</td>
                  <td>{getHospitalNameById(data.hospital_id, hospitals)}</td>
                  <td>{new Date(data.appointment_date).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No Visa Send To Hospitals.</p>
      )} */}
    </>
  );
};

export default VisaInvitationEmailList;
