import React, { useEffect, useMemo, useState } from "react";
import { useWhatsappApi } from "../../context/whatsappContex";
import "./userList.css";
import LeftSlider from "../../Inc/LeftSlider";
import Header from "../../Inc/header/Header";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import AdminUserList from "./AdminUserList";

const UserList = () => {
  const { teamData, fetchTeamData } = useWhatsappApi();
  const [leadCount, setLeadCount] = useState([])

  const userLeadNumber = async () => {
    try {
      const response = await axiosInstance.get("/countAgent")
      setLeadCount(response.data)
    } catch (error) {
      console.log("Error fetching user wise lead list", error)
    }
  }

  useEffect(() => {
    fetchTeamData();
    userLeadNumber()
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      try {
        const response = await axiosInstance.get(`/userdelete/${id}`);

        alert("User deleted successfully");
        fetchTeamData(); // Refresh the user list
      } catch (error) {
        console.error("There was an error deleting the user!", error);
        alert("An error occurred while deleting the user");
      }
    }
  };

  return (
    <>
      <section id="medflick-mod">
        <Header />
        <div className="medflick-mod">
          <LeftSlider path="user-list" />
          <div className="medflick-mod-right">
            <AdminUserList leadCount={leadCount} />
            <div className="user-list">
              <div className="scrollbar">
                {teamData &&
                  teamData.map((user) => (
                    <div key={user.id} className="user-card">
                      <p>
                        <strong>Name:</strong> {user?.name}
                      </p>
                      <p>
                        <strong>Email:</strong> {user?.email}
                      </p>
                      <p>
                        <strong>Username:</strong> {user?.username}
                      </p>
                      <p>
                        <strong>Phone:</strong> {user?.phone}
                      </p>
                      <p>
                        <strong>Role: </strong>
                        {user?.role === "1"
                          ? "Superadmin"
                          : user?.role === "2"
                            ? "Admin"
                            : user?.role === "3"
                              ? "Manager"
                              : "agent"}
                      </p>
                      <button
                        onClick={() => handleDelete(user.id)}
                        className="delete-button"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserList;
