import Select from "react-select";
import { useMedflickApi } from "../../context/medflickContext";
import { useEffect } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useState } from "react";
import { format } from "date-fns";

const AllTreatmentSelect = ({
  patientId,
  userId,
  leadId,
  treatmentId,
  getLeadbyId,
  id,
  treatmentsList,
  treatmentUploadList,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);

  const mergeAndFilterLists = (list1, list2) => {
    const combinedList = [...list1, ...list2];
    const uniqueList = [];

    const ids = new Set();

    combinedList.forEach((item) => {
      if (!ids.has(item.id)) {
        ids.add(item.id);
        uniqueList.push(item);
      }
    });

    return uniqueList;
  };

  const combinedTreatmentList = mergeAndFilterLists(
    treatmentsList,
    treatmentUploadList
  );

  const selectTreatment = () => {
    if (treatmentId) {
      const selected = combinedTreatmentList.find(
        (treatment) => treatment.id === treatmentId
      );
      if (selected) {
        setSelectedTreatment({ value: selected.id, label: selected.name });
      }
    } else {
      setSelectedTreatment(null);
    }
  };

  useEffect(() => {
    selectTreatment();
  }, [treatmentId]);

  const handleSelectChange = async (selectedOption) => {
    setLoading(true);
    const { value, label } = selectedOption;
    const formatDate = (date) => {
      const day = format(date, "do");
      const month = format(date, "MMMM");
      const year = format(date, "yyyy");
      const time = format(date, "h:mm a");
      return `${day} ${month} ${year} ${time}`;
    };

    const currentDateTime = formatDate(new Date());

    try {
      const response = await axiosInstance.post("/assignSpecialityPatient", {
        lead_id: leadId,
        user_id: userId,
        patient_id: patientId,
        speciality_id: value,
        speciality_name: label,
        speciality_date: currentDateTime,
      });
      alert("Request was successful");
      getLeadbyId(id);
    } catch (error) {
      console.error("There was an error making the request:", error);
      alert("An error occurred while making the request");
    } finally {
      setLoading(false);
    }
  };

  const options = combinedTreatmentList.map((treatment) => ({
    value: treatment.id,
    label: treatment.name,
  }));

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <Select
        options={options}
        placeholder="Select Treatment"
        onChange={handleSelectChange}
        isDisabled={loading}
        value={selectedTreatment}
      />
    </>
  );
};

export default AllTreatmentSelect;
