import React, { useState } from 'react'
import { FaSearchPlus, FaSearchMinus } from "react-icons/fa";

const ZoomInOut = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const handleZoomIn = () => {
        const rootElement = document.getElementById("root");
        if (rootElement.requestFullscreen) {
            rootElement.requestFullscreen();
        } else if (rootElement.webkitRequestFullscreen) {
            rootElement.webkitRequestFullscreen();
        } else if (rootElement.msRequestFullscreen) {
            rootElement.msRequestFullscreen();
        }
        setIsFullscreen(true); // Update state to fullscreen
    };

    const handleZoomOut = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            setIsFullscreen(false); // Update state to normal
        }
    };
    return (
        <>
            <div className="zoom-controls" style={{ marginLeft: "auto" }}>
                <button onClick={isFullscreen ? handleZoomOut : handleZoomIn}>
                    {isFullscreen ? (
                        <FaSearchMinus size={20} style={{ marginRight: "5px" }} />
                    ) : (
                        <FaSearchPlus size={20} style={{ marginRight: "5px" }} />
                    )}
                    {isFullscreen ? "Zoom Out" : "Zoom In"}
                </button>
            </div>
        </>

    )
}

export default ZoomInOut