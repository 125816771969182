import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LeftSlider from "../Inc/LeftSlider";
import { useGetLeadApi } from "../context/getAllLeadContext";
import "../page/patientDetails/patientDetail.css";
import { decryptId, encryptId } from "../utils/cryptoUtils";
import LeadType from "../common/LeadType";
import MessagePopup from "../page/patientDetails/MessagePopup";
import { usePostArrivalApi } from "../context/getPostArrivalContext";
import PatientDischargeList from "./patient-discharge/PatientDischargeList";
import { useMedflickApi } from "../context/medflickContext";
import DoctorConsultList from "./doctor-consult/DoctorConsultList";
import PostArrivalList from "./post-arrival-form/PostArrivalList";
import PatientAdmisiionList from "./patient-admission/PatientAdmisiionList";
const formatDate = (isoString) => {
  const date = new Date(isoString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";

  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${day}${getDaySuffix(
    day
  )} ${month} ${year} <span></span> ${formattedHours}:${formattedMinutes} ${period}`;
};

const getDaySuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const Main = () => {
  const { pid } = useParams();
  const [showMessage, setShowMessage] = useState(false);
  const [conversationStatus, setConversationStatus] = useState("");
  const id = decryptId(pid);
  const {
    getHospitalImageById,
    getHospitalNameById,
    hospitals,
    getAllHospitals,
  } = useMedflickApi();
  const {
    allLoading,
    getDoctorConsultation,
    doctorConsultationList,
    getPostDischargeList,
    postDischargeList,
    getpostarrivalsList,
    postArrivalList,
    getpatientAdmisiionList,
    patientAdmisList,
  } = usePostArrivalApi();
  const {
    leadDetail,
    patientSpeciality,
    patienLeadDetail,
    getLeadbyId,
    userLeadDetail,
    patientJourney,
    getConversion,
    patientConversation,
  } = useGetLeadApi();
  const patinetId = patienLeadDetail?.id;
  const userId = userLeadDetail[0]?.id;
  const patientJourneyId = patientJourney?.id;

  useEffect(() => {
    getLeadbyId(id);
    getConversion(patinetId, userId, patientJourneyId);
    getDoctorConsultation(patinetId, userId);
    getPostDischargeList(patinetId, userId);
    getpostarrivalsList(patinetId, userId);
    getpatientAdmisiionList(patinetId, userId);
    getAllHospitals();
  }, [id, patinetId, userId, patientJourneyId]);

  const getBackgroundColor = (lead_type) => {
    switch (lead_type) {
      case "Warm":
        return "#c6c61c";
      case "Hot":
        return "green";
      case "Cold":
        return "orange";
      case "Dead":
        return "red";
      default:
        return;
    }
  };

  const toggleMessage = (message) => {
    setShowMessage(!showMessage);
    setConversationStatus(message);
  };


  return (
    <>
      {allLoading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <section id="medflick-mod">
        <div className="head-top">
          <div className="head-top-left">
            <img src="/images/2024/01/logo.png" />
          </div>
          <div className="head-top-right">
            <Link to={`/patient/${pid}`}>
              <i className="fa  fa-angle-left"></i> Go back
            </Link>
            {/* <Link
              to="/post-arrival"
              className="post-arrival-button"
              style={{ color: "#fff" }}
            >
              <FaPlaneArrival className="icon" />
              Post Arrival
            </Link> */}
            {/* <div className="notification-bell">
              <FaBell className="bell-icon" />
              {processedCount > 0 && (
                <span className="badge">{processedCount}</span>
              )}
            </div> */}
          </div>
        </div>
        <div className="medflick-mod">
          <LeftSlider />

          <div className="medflick-mod-right">
            <div className="scrollbar-1">
              <div
                className="patient-dashboard-doc"
                style={{
                  background: getBackgroundColor(leadDetail?.lead_type),
                }}
              >
                <div className="patient-dashboard-box">
                  <div className="patient-img-box">
                    {/* <div className="css-hra0ve"></div> */}
                    <img src="/images/userO.png" />
                  </div>
                  <div className="patient-doc-box">
                    <h2
                      style={{
                        textAlign: "start",
                        color: leadDetail?.lead_type ? "#fff" : null,
                      }}
                    >
                      {leadDetail?.patient_name}
                    </h2>
                    <div className="patient-doc-data">
                      <ul>
                        {/* <li>
                          <img src="/images/2024/01/03/1.png" /> Female
                        </li>
                        <li>
                          <img src="/images/2024/01/03/2.png" /> India
                        </li> */}
                        <li
                          style={{
                            color: leadDetail?.lead_type ? "#fff" : null,
                          }}
                        >
                          <img src="/images/2024/01/03/3.png" />{" "}
                          {patientSpeciality?.name}
                        </li>
                        <li
                          style={{
                            color: leadDetail?.lead_type ? "#fff" : null,
                          }}
                        >
                          <img src="/images/2024/01/03/4.png" />{" "}
                          {patienLeadDetail?.phone}
                        </li>
                        <li
                          style={{
                            color: leadDetail?.lead_type ? "#fff" : null,
                          }}
                        >
                          <img src="/images/2024/01/03/4.png" />{" "}
                          {patienLeadDetail?.email}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="patient-dashboard-query">
                  <h3
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                  >
                    Query
                  </h3>
                  <p
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: formatDate(leadDetail?.created_at),
                    }}
                  />
                </div>
                <div className="patient-dashboard-reply">
                  <h3
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                  >
                    Source
                  </h3>
                  <p
                    style={{
                      color: leadDetail?.lead_type ? "#fff" : null,
                    }}
                  >
                    {leadDetail.platform ? leadDetail.platform : "Manually Add"}
                  </p>
                </div>
                <div className="patient-dashboard-msg">
                  <LeadType
                    leadId={id}
                    leadtype={leadDetail?.lead_type}
                    getLeadbyId={getLeadbyId}
                  />
                  {/* <a
                    href={
                      patienLeadDetail?.phone
                        ? `https://wa.me/${patienLeadDetail.phone}`
                        : "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Message
                  </a> */}
                </div>
              </div>

              <div className="patient-dashboard-casebox">
                <div className="patient-dashboard-case-left">
                  <h2>
                    Case History{" "}
                    {/* <a href="#">
                      <img src="/images/2024/01/download.png" /> Download
                    </a> */}
                  </h2>
                  <ul>
                    <li
                      className={` ${
                        postArrivalList.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img "></div>
                      <p>Post Arrival</p>
                    </li>
                    <li
                      className={` ${
                        doctorConsultationList.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img "></div>
                      <p>Doctor Consultation</p>
                    </li>
                    <li
                      className={` ${
                        patientAdmisList.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img "></div>
                      <p>Patient Admission</p>
                    </li>
                    <li
                      className={` ${
                        postDischargeList.length > 0 ? "active" : ""
                      }`}
                    >
                      <div className="updates-img "></div>
                      <p>Patient Discharge</p>
                    </li>
                  </ul>
                </div>
                <div className="patient-dashboard-case-right">
                  <div className="patient-dashboard-opinion">
                    <h2>
                      Post Arrival
                      <Link
                        style={{ color: "#fff", cursor: "pointer" }}
                        to={`/post-arrival-form/${encryptId(
                          patinetId
                        )}/${encryptId(userId)}`}
                      >
                        Update Detail
                      </Link>
                    </h2>
                    <PostArrivalList
                      postArrivalList={postArrivalList}
                      encryptId={encryptId}
                      getHospitalImageById={getHospitalImageById}
                      getHospitalNameById={getHospitalNameById}
                      hospitals={hospitals}
                    />
                  </div>
                  <div className="patient-dashboard-opinion">
                    <h2>
                      Doctor Consultation
                      <Link
                        style={{ color: "#fff", cursor: "pointer" }}
                        to={`/doctor-consult/${encryptId(
                          patinetId
                        )}/${encryptId(userId)}`}
                      >
                        Update Detail
                      </Link>
                    </h2>
                    <DoctorConsultList
                      doctorConsultationList={doctorConsultationList}
                      encryptId={encryptId}
                      getHospitalImageById={getHospitalImageById}
                      getHospitalNameById={getHospitalNameById}
                      hospitals={hospitals}
                    />
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Patient Admission
                      <Link
                        style={{ color: "#fff", cursor: "pointer" }}
                        to={`/patient-admission/${encryptId(
                          patinetId
                        )}/${encryptId(userId)}`}
                      >
                        Update Detail
                      </Link>
                    </h2>

                    <PatientAdmisiionList
                      patientAdmisList={patientAdmisList}
                      encryptId={encryptId}
                      getHospitalImageById={getHospitalImageById}
                      getHospitalNameById={getHospitalNameById}
                      hospitals={hospitals}
                    />
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Patient Discharge
                      <Link
                        style={{ color: "#fff", cursor: "pointer" }}
                        to={`/patient-discharge/${encryptId(
                          patinetId
                        )}/${encryptId(userId)}`}
                      >
                        Update Detail
                      </Link>
                    </h2>
                    <PatientDischargeList
                      postDischargeList={postDischargeList}
                      encryptId={encryptId}
                      getHospitalImageById={getHospitalImageById}
                      getHospitalNameById={getHospitalNameById}
                      hospitals={hospitals}
                    />
                  </div>

                  <div className="patient-dashboard-opinion">
                    <h2>
                      Conversation{" "}
                      <a href="#" onClick={() => toggleMessage("PostArrival")}>
                        Message
                      </a>
                    </h2>
                    {patientConversation
                      ?.filter((e) => e.status === "PostArrival")
                      .map((e) => (
                        <div key={e?.id} className="conversation-item">
                          <p className="health-description">
                            {e?.health_description}
                          </p>
                          <p
                            className="conversation-date"
                            dangerouslySetInnerHTML={{
                              __html: formatDate(e?.created_at),
                            }}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MessagePopup
        toggleMessage={toggleMessage}
        showMessage={showMessage}
        patientId={patienLeadDetail?.id}
        userId={userLeadDetail[0]?.id}
        getConversion={getConversion}
        patientJourneyId={patientJourneyId}
        conversationStatus={conversationStatus}
      />
    </>
  );
};

export default Main;
