import React, { useEffect, useState } from "react";
import LeftSlider from "../../Inc/LeftSlider";
import "../post-arrival-form/postarrival.css";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import Select from "react-select";
import { useMedflickApi } from "../../context/medflickContext";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const PatientDischarge = () => {
  const { pid, uid } = useParams();
  const { getAllHospitals, hospitals, doctors } = useMedflickApi();
  const [loading, setLoading] = useState(false);
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const [selectedHospitals, setSelectedHospitals] = useState("");
  const [selectedDoctors, setSelectedDoctors] = useState("");
  const [patientPrescription, setPatientPrescription] = useState(null);
  const [formData, setFormData] = useState({
    post_discharge: "",
    description: "",
    status: "",
  });

  useEffect(() => {
    getAllHospitals();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlehospitalChange = (selectedOptions) => {
    setSelectedHospitals(selectedOptions);
  };

  const handleDoctorChange = (selectedOptions) => {
    setSelectedDoctors(selectedOptions);
  };

  const hospitalOptions = hospitals?.map((hospital) => ({
    value: hospital.id,
    label: hospital.name,
  }));

  const doctorOptions = doctors?.map((hospital) => ({
    value: hospital.id,
    label: "Dr. " + hospital.first_name + " " + hospital.last_name,
  }));

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPatientPrescription(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedHospitals) {
      alert("Please Select Hospital");
      return;
    }

    if (!selectedDoctors) {
      alert("Please Select Doctor");
      return;
    }

    if (!formData?.post_discharge) {
      alert("Please Select Post discharge Status");
      return;
    }

    setLoading(true);

    try {
      const data = {
        patient_id: patinetId,
        user_id: userId,
        hospital_id: selectedHospitals?.value,
        doctor_id: selectedDoctors?.value,
        post_discharge: formData?.post_discharge,
        description: formData?.description,
      };

      const response = await axiosInstance.post("/post_discharge", data);

      if (response.status === 200) {
        alert("Form submitted successfully!");
        setFormData({
          post_discharge: "",
          description: "",
          status: "",
        });
        setPatientPrescription(null);
        setSelectedHospitals(null);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section id="medflick-mod">
        <div className="medflick-mod">
          <LeftSlider />
          <div className="medflick-mod-right">
            <div className="scrollbar-1">
              <div className="post-arrival-form">
                <h2>Patient Discharge Form</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="pickup">Select Hospital</label>
                    <Select
                      name="hospitals"
                      options={hospitalOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedHospitals}
                      onChange={handlehospitalChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="doctor">Select Doctor</label>
                    <Select
                      name="hospitals"
                      options={doctorOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={selectedDoctors}
                      onChange={handleDoctorChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="post_discharge">Post Discharge</label>
                    <select
                      id="status"
                      name="post_discharge"
                      value={formData.treatment_plan}
                      onChange={handleChange}
                    >
                      <option value="">Select Option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      name="description"
                      placeholder="Enter description"
                      value={formData.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="submit-button"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PatientDischarge;
