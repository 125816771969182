import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMedflickApi } from "../../context/medflickContext";
import { decryptId, encryptId } from "../../utils/cryptoUtils";
import { usePostArrivalApi } from "../../context/getPostArrivalContext";
import LeftSlider from "../../Inc/LeftSlider";
import { Link } from "react-router-dom";

const PostArrivalDetail = () => {
  const { pid, uid, idd } = useParams();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const id = decryptId(idd);
  const {
    getHospitalNameById,
    getAllHospitals,
    hospitals,
    getHospitalImageById,
  } = useMedflickApi();

  const { getpostarrivalsList, postArrivalList } = usePostArrivalApi();

  useEffect(() => {
    getpostarrivalsList(patinetId, userId);
  }, [patinetId, userId]);

  const detail = postArrivalList?.find((item) => item.id === parseInt(id));
  return (
    <>
      <section id="medflick-mod">
        {/* <div className="head-top">
          <div className="head-top-left">
            <img src="/images/2024/01/logo.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div> */}
        <div className="medflick-mod">
          <LeftSlider />
          <div className="medflick-mod-right">
            <div className="table-container">
              <h1 style={{ fontSize: "31px" }}>
                Post post arrival detail

              </h1>
              <table className="details-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    {/* <th>Patient Name</th> */}
                    <th>Pickup</th>
                    <th>Hotel Booking</th>
                    <th>Hospital Name</th>
                    <th>Hotel Name</th>
                    <th>Hotel Address</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{detail.id}</td>
                    {/* <td>{leadDetail?.patient_name}</td> */}
                    <td>{detail?.pickup}</td>
                    <td>{detail?.hotel_booking}</td>
                    <td>
                      {getHospitalNameById(detail?.hospital_id, hospitals)}
                    </td>
                    <td>{detail?.hotel_name}</td>
                    <td>{detail?.hotel_address}</td>
                    <td>{detail?.description}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2
              style={{
                fontSize: "28px",
                marginTop: "20px",
                textAlign: "start",
              }}
            >
              List of Ticket Submission to Hospitals
            </h2>
            {postArrivalList?.length > 0 ? (
              <div
                className="opinion-grid"
                style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
              >
                {postArrivalList.map((opinion) => (
                  <div key={opinion?.id} className="opinion-item">
                    <Link
                      to={`/post-arrival-detail/${encryptId(
                        opinion?.patient_id
                      )}/${encryptId(opinion?.user_id)}/${encryptId(
                        opinion?.id
                      )}`}
                    >
                      <div class="image-container">
                        <img
                          src={`https://images.medflick.com/hospital/${getHospitalImageById(
                            opinion?.hospital_id,
                            hospitals
                          )}`}
                          alt="Hospital"
                          className="hospital-image"
                        />
                        <span className="hospital-name">
                          {getHospitalNameById(opinion?.hospital_id, hospitals)}
                        </span>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p>No uploaded opinions available.</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PostArrivalDetail;
