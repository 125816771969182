import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { decryptId } from "../../utils/cryptoUtils";
import { useMedflickApi } from "../../context/medflickContext";
import LeftSlider from "../../Inc/LeftSlider";
import { useGetLeadApi } from "../../context/getAllLeadContext";

const ConvertedLeadDetail = () => {
  const { pid, uid, idd } = useParams();
  const patinetId = decryptId(pid);
  const userId = decryptId(uid);
  const id = decryptId(idd);
  const { getConvertedList, convertedLead } = useGetLeadApi();
  const {
    getHospitalNameById,
    getAllHospitals,
    hospitals,
    getHospitalImageById,
  } = useMedflickApi();

  useEffect(() => {
    getAllHospitals();
    getConvertedList(patinetId, userId);
  }, [patinetId, userId]);

  const detail = convertedLead?.find((item) => item.id === parseInt(id));
  return (
    <>
      <section id="medflick-mod">
        {/* <div className="head-top">
          <div className="head-top-left">
            <img src="/images/2024/01/logo.png" />{" "}
          </div>
          <div className="head-top-right">
            <Link to={`/patient`}>
              {" "}
              <i className="fa  fa-angle-left"></i> Go back{" "}
            </Link>
          </div>
        </div> */}
        <div className="medflick-mod">
          <LeftSlider />
          <div className="medflick-mod-right">
            <div className="table-container">
              <h1 style={{ fontSize: "31px" }}>
                Opinion Send to{" "}
                <span style={{ color: "#ff6800" }}>
                  {getHospitalNameById(detail?.hospital_id, hospitals)}
                </span>{" "}
              </h1>
              <table className="details-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Hospital Name</th>
                    <th>Cost</th>
                    <th>Treatment Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{detail.id}</td>
                    <td>
                      {getHospitalNameById(detail?.hospital_id, hospitals)}
                    </td>
                    <td>${detail?.cost}</td>
                    <td>{detail?.leadType}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConvertedLeadDetail;
