import React, { useState, useEffect } from "react";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useUser } from "../../context/UserContext";
import AdminUserList from "../../page/userList/AdminUserList";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
ChartJS.register(ArcElement, Tooltip, Legend);


const Graph = ({ adminLead }) => {
  const [leadCount, setLeadCount] = useState([])
  const { totalLead } = useGetLeadApi();
  const { user } = useUser()
  const alllead = totalLead?.totalLeads;
  const totalLeadsRelevant = totalLead?.totalLeadsRelevant;
  const totalFollowUp = totalLead?.totalReminder;
  const totalCost = totalLead?.totalCost;
  const totalpatientArrivals = totalLead?.patientPostarrivals;

  const userLeadNumber = async () => {
    try {
      const response = await axiosInstance.get("/countAgent")
      const firstFourData = response.data.slice(0, 4);
      setLeadCount(firstFourData);
    } catch (error) {
      console.log("Error fetching user wise lead list", error)
    }
  }

  useEffect(() => {
    userLeadNumber()
  }, [])

  const data = {
    labels: [
      "Total Leads",
      "Relevant Leads",
      "Total Reminders",
      "Total Cost",
      "Post Arrivals",
    ],
    datasets: [
      {
        label: "Statistics",
        data: [777, 67, 3, 23050, 6],
        backgroundColor: [
          "#ff6384", // Red
          "#36a2eb", // Blue
          "#ffcd56", // Yellow
          "#4bc0c0", // Teal
          "#9966ff", // Purple
          "#ff9f40", // Orange
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top", // Can be 'top', 'left', 'right', or 'bottom'
      },
    },
  };
  return (
    <>
      <div className="medflick-home-box">
        {/* <div style={{ width: "50%", margin: "0 auto" }}>
          <Doughnut data={data} options={options} />
        </div> */}
        <ul>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Leads</h4>
                <h2>
                  {/* {alllead ? alllead : adminLead} */}
                  {alllead}
                  {/* <span>+8%</span> */}
                </h2>
              </div>
              {/* <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div> */}
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              {/* <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div> */}
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Conversion Cost</h4>
                <h2>${totalCost}</h2>
              </div>

              {/* <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div> */}
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              {/* <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div> */}
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Relevent Leads</h4>
                <h2>{totalLeadsRelevant}</h2>
              </div>
              {/* <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div> */}
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              {/* <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div> */}
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Arrivals</h4>
                <h2>{totalpatientArrivals}</h2>
              </div>
              {/* <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div> */}
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              {/* <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div> */}
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Follow UPS</h4>
                <h2>{totalFollowUp}</h2>
              </div>
              {/* <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div> */}
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              {/* <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div> */}
            </div>
          </li>
        </ul>
      </div>
      {user?.role === "1" && (
        <AdminUserList leadCount={leadCount} dashbaord={true} />
      )}

      {user?.role === "2" && (
        <div className="medflick-home-box">
          <ul>
            <li>
              <div className="total-leads-box">
                <div className="leads-text">
                  <h4>Total Leads Assign To You</h4>
                  <h2>
                    {/* {alllead ? alllead : adminLead} */}
                    {adminLead}
                    {/* <span>+8%</span> */}
                  </h2>
                </div>
                {/* <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div> */}
              </div>
              <div className="corp-reports-day">
                <img src="/images/2024/01/bg-1.png" />
                {/* <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div> */}
              </div>
            </li>
          </ul>

        </div>
      )}

    </>
  );
};

export default Graph;
