import React from "react";
import { Link } from "react-router-dom";

const VisaUploadList = ({
  visaData,
  patinetName,
  getBackgroundColor,
  leadDetail,
  getHospitalNameById,
  hospitals,
  encryptId,
  getHospitalImageById,
}) => {
  return (
    <>
      {visaData?.length > 0 ? (
        <div className="opinion-grid">
          {visaData.map((opinion) => (
            <div key={opinion?.id} className="opinion-item">
              <Link
                to={`/hospital-visa-submission/${encryptId(
                  opinion?.patient_id
                )}/${encryptId(opinion?.user_id)}/${encryptId(opinion?.id)}`}
              >
                <img
                  src={`https://images.medflick.com/hospital/${getHospitalImageById(
                    opinion?.hospital_id,
                    hospitals
                  )}`}
                  alt="Hospital"
                  className="hospital-image"
                />
                <span className="hospital-name">
                  {getHospitalNameById(opinion?.hospital_id, hospitals)}
                </span>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No Hospital Visa submission available.</p>
      )}
      {/* {visaData?.length > 0 ? (
        <div className="table-container">
          <table className="visa-table">
            <thead>
              <tr>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Patient Name
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Patient Passport Number
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Patient Passport
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Patient Visa
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Name (1)
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Passport Number (1)
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Passport (1)
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Attendant Visa (1)
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Hospital
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Country
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Subject
                </th>
                <th
                  style={{
                    background: getBackgroundColor(leadDetail?.lead_type),
                  }}
                >
                  Appointment Date
                </th>
              </tr>
            </thead>
            <tbody>
              {visaData && visaData.length > 0 ? (
                visaData.map((visa, index) => (
                  <tr key={visa.id}>
                    <td>{visa.id}</td>
                    <td>{patinetName}</td>
                    <td>{visa?.passport_number}</td>
                    <td>
                      <a
                        href={visa.patient_passport}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a>
                    </td>
                    <td>
                      <a
                        href={visa.pvisa}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a>
                    </td>
                    <td>{visa.attendant_name1}</td>
                    <td>{visa.att_passport_number1}</td>
                    <td>
                      <a
                        href={visa.attendant_passport1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a>
                    </td>
                    <td>
                      <a
                        href={visa.attvisa1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View File
                      </a>
                    </td>
                    <td>{getHospitalNameById(visa.hospital_id, hospitals)}</td>
                    <td>{visa.country}</td>
                    <td>{visa.subject}</td>
                    <td>
                      {new Date(visa.appointment_date).toLocaleDateString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No visa data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p style={{ paddingTop: "10px" }}>No Visa Send To Hospitals.</p>
      )} */}
    </>
  );
};

export default VisaUploadList;
