import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Graph = () => {
  const staticData = {
    "2024-11-20": { Facebook: 10, Whatsapp: 5, Website: 5 },
    "2024-11-21": { Facebook: 8, Whatsapp: 7, Website: 2 },
    "2024-11-22": { Facebook: 12, Whatsapp: 6, Website: 4 },
    "2024-11-23": { Facebook: 15, Whatsapp: 9, Website: 5 },
    "2024-11-24": { Facebook: 7, Whatsapp: 8, Website: 3 },
    "2024-11-25": { Facebook: 11, Whatsapp: 10, Website: 6 },
    "2024-11-26": { Facebook: 13, Whatsapp: 12, Website: 4 },
  };
  // Process static data for Chart.js
  const dates = Object.keys(staticData); // ["2024-11-20", "2024-11-21", ...]
  const platforms = ["Facebook", "Whatsapp", "Website"]; // Fixed list of platforms

  // Assign unique colors to each platform  FF6384
  const platformColors = {
    Facebook: "#36A2EB", // Red for Facebook
    Whatsapp: "green", // Blue for Whatsapp
    Website: "#ff6800", // Yellow for Website
  };

  // Generate datasets for each platform
  const datasets = platforms.map((platform) => ({
    label: platform,
    data: dates.map((date) => staticData[date][platform] || 0),
    backgroundColor: platformColors[platform], // Use the assigned color
  }));

  const chartData = {
    labels: dates,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Weekly Lead Stats by Date and Platform",
      },
    },
    scales: {
      x: {
        stacked: false, // Bars will be side-by-side
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <div style={{ width: "80%", margin: "0 auto" }}>
        <h2>Weekly Lead Stats</h2>
        <Bar data={chartData} options={options} />
      </div>
    </>
  );
};

export default Graph;
