import React from "react";
import { Link } from "react-router-dom";

const TicketSubmissionList = ({
  getSendTicket,
  encryptId,
  getHospitalImageById,
  hospitals,
  getHospitalNameById,
}) => {
  return (
    <>
      {getSendTicket?.length > 0 ? (
        <div className="opinion-grid">
          {getSendTicket.map((opinion) => (
            <div key={opinion?.id} className="opinion-item">
              <Link
                to={`/ticket-submission-detail/${encryptId(
                  opinion?.patient_id
                )}/${encryptId(opinion?.user_id)}/${encryptId(opinion?.id)}`}
              >
                <div class="image-container">
                  <img
                    src={`https://images.medflick.com/hospital/${getHospitalImageById(
                      opinion?.hospital_id,
                      hospitals
                    )}`}
                    alt="Hospital"
                    className="hospital-image"
                  />
                  <span className="hospital-name">
                    {getHospitalNameById(opinion?.hospital_id, hospitals)}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No uploaded opinions available.</p>
      )}
      {/* {getSendTicket.length > 0 ? (
                      <div className="table-container">
                        <table className="details-table">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                ID
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Patient Name
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Visa Upload
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Ticket Upload
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Passport Number
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 1
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 1
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 2
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 2
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 3
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 3
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Name 4
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Attendant Passport 4
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Embassy
                              </th>
                              <th
                                style={{
                                  background: getBackgroundColor(
                                    leadDetail?.lead_type
                                  ),
                                }}
                              >
                                Patient Passport
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {getSendTicket?.map((detail) => (
                              <tr key={detail.id}>
                                <td>{detail.id}</td>
                                <td>{leadDetail?.patient_name}</td>
                                <td>
                                  <a
                                    href={detail.visa_upload}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Patient Visa
                                  </a>
                                </td>
                                <td>
                                  <a
                                    href={detail.ticket_upload}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Patient Ticket
                                  </a>
                                </td>
                                <td>{detail.passport_number}</td>
                                <td>{detail.attendant_name1}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport1}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 1
                                  </a>
                                </td>
                                <td>{detail.attendant_name2}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport2}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 2
                                  </a>
                                </td>
                                <td>{detail.attendant_name3}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport3}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 3
                                  </a>
                                </td>
                                <td>{detail.attendant_name4}</td>
                                <td>
                                  <a
                                    href={detail.attendant_passport4}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Attendant Passport 4
                                  </a>
                                </td>
                                <td>{detail.embassy}</td>
                                <td>
                                  <a
                                    href={detail.patient_passport}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View Patient Passport
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>No Ticket Found</p>
                    )} */}
    </>
  );
};

export default TicketSubmissionList;
